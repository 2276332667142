.tos-container {
    max-width: 800px;
    margin: 80px auto; /* Adjust the margin to avoid being cut off by the nav */
    padding: 20px;

}

.tos-title {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333;
}

.tos-content p {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.6;
    color: #333;
}

.tos-content strong {
    font-weight: bold;
}
