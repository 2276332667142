body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.blog-list-container {
    padding: 40px 20px;
    max-width: 1100px;
    margin: 80px auto; /* Add margin-top to avoid being cut off by the nav */
}

.blog-title {
    text-align: center;
    font-size: 3em;
    margin-bottom: 50px;
    color: #333;
    font-weight: 500;
}

.blog-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}

.blog-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    width: calc(40% - 20px); /* Smaller cards, two per row */
    margin-bottom: 20px;
}

.blog-card:hover {
    transform: translateY(-5px);
}

.blog-image {
    width: 100%;
    height: 150px; /* Reduced height for smaller cards */
    object-fit: cover;
}

.blog-card-title {
    font-size: 1.5em;
    margin: 15px 10px 5px;
}

.blog-card-date {
    color: #666;
    margin: 0 10px 10px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .blog-card {
        width: calc(100% - 20px); /* Full width for mobile devices */
    }
}
