body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.privacy-container {
    padding: 40px 20px;
    max-width: 800px;
    margin: 80px auto; /* Add margin-top to avoid being cut off by the nav */
}

.privacy-title {
    text-align: center;
    font-size: 3em;
    margin-bottom: 50px;
    color: #333;
    font-weight: 500;
}

.privacy-content p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
}
