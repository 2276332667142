.featured-jobs-wrapper {
    text-align: left;
    margin-bottom: 20px;
}

.featured-jobs-title {
    font-size: 36px;
    font-weight: 500;
    margin-left: 20px;
    margin-bottom: 22px;
}

.featured-jobs-subtitle {
    font-size: 16px;
    margin-bottom: 10px;
    margin-left: 20px;
}

.featured-jobs-container {
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #e5e7eb; /* Light gray border to match the screenshot */
    border-radius: 20px; /* Adjusted border-radius for softer corners */
    width: 500px;
    height: 580px; /* Adjust height as needed */
    margin: 0 auto;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow for the outline */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items to the top */
}


.featured-jobs-filter {
    display: flex;
    justify-content: left; /* Align items to the start */
    align-items: center;
    margin-bottom: 10px;
}

.industry-label {
    margin-right: 5px;
    font-size: 16px;
}

.industry-dropdown {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    border: 1px solid #000;
    margin-right: 4px;
}

.industry-dropdown option {
    font-size: 14px;
}

.job-cards-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.job-type-toggle {
    padding: 5px 5px;
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
    margin-left: 10px;
    margin-right: auto;
    font-size: 15px;
    height: 38px;
    white-space: nowrap;
    padding-left: 15px;
    padding-right: 15px;
}

.job-type-toggle:hover {
    background-color: #e5e7eb;
}
