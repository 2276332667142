.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

.carousel-wrapper {
  overflow: hidden;
  position: relative;
  width: 75%;
  height: auto;
  display: flex;
  align-items: center;
}

.carousel {
  display: flex;
  justify-content: flex-start;
}

.slide {
  flex: 0 0 auto;
  max-height: 30px; /* Adjust as necessary */
  object-fit: contain;
  margin: 0 20px;
}
