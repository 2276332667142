.auto-apply-content {
    /* Add your styles here */
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 6px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.auto-apply-content h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.auto-apply-content p {
    font-size: 16px;
}

.table-container {
    overflow: hidden;
    border-radius: 6px; /* Adjusted border-radius for rounded corners */
}

.table-wrapper {
    overflow-y: scroll;
    height: 525px;
    scrollbar-width: thin;
}

table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

thead th:first-child {
    border-top-left-radius: 4px; /* Round top-left corner */
}

thead th:last-child {
    border-top-right-radius: 4px; /* Round top-right corner */
}
