/* src/components/faq/faqmobile.css */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap'); /* Import Inter font with weights 400 and 600 */

.faq-container-mobile {
    padding: 1rem;
    border-radius: 8px;
    font-family: 'Inter', sans-serif; /* Add font family */
}

.faq-title-mobile {
    margin-bottom: 30px;
    font-size: 2rem;
    line-height: 3rem;
}

.faq-content-mobile {
    max-width: 95%;
    margin: 0 auto;
}

.faq-item-mobile {
    margin-bottom: 1rem;
}

.faq-item-mobile.active {
    width: 100%;
}

.faq-question-mobile {
    padding: 1rem;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s, box-shadow 0.3s, border 0.3s;
    font-weight: 600; /* Make questions bold */
}

.faq-question-mobile:hover {
    text-decoration: underline;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
}

.faq-question-mobile h3 {
    margin: 0;
    color: #333;
    font-weight: 600; /* Ensure h3 is bold */
}

.faq-question-mobile .arrow {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: 10px;
    transition: transform 0.3s;
    background-size: contain;
    background-repeat: no-repeat;
}

.faq-question-mobile .arrow.down {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/></svg>'); /* SVG for down arrow */
}

.faq-question-mobile .arrow.up {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"/></svg>'); /* SVG for up arrow */
}

.faq-answer-mobile {
    padding: 1rem;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 0.5rem;
}

.faq-divider-mobile {
    margin-top: 1rem;
    border: 0;
    height: 1px;
    background: #ddd;
}
