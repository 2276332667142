@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap'); /* Import Inter font with weights 400 and 600 */

.faq-container {
    padding: 1rem;
    border-radius: 8px;
    font-family: 'Inter', sans-serif; /* Add font family */
}

.faq-title {
    margin-bottom: 50px;
}

.faq-content {
    max-width: 800px;
    min-width: 800px;
    margin: 0 auto;
}

.faq-item {
    margin-bottom: 0.5rem; /* Reduced margin for less space between items */
}

.faq-item.active {
    width: 100%;
}

.faq-question {
    padding: 0rem;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s, box-shadow 0.3s, border 0.3s;
}

.faq-question:hover {
    text-decoration: underline;
}

.faq-question h3 {
    margin: 0;
    color: #333;
    font-weight: 500; /* Ensure h3 is bold */
}

.faq-question .arrow {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: 10px;
    transition: transform 0.3s;
    background-size: contain;
    background-repeat: no-repeat;
}

.faq-question .arrow.down {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/></svg>'); /* SVG for down arrow */
}

.faq-question .arrow.up {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"/></svg>'); /* SVG for up arrow */
}

.faq-answer {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease, padding 0.5s ease;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 0.5rem;
    padding: 0;
}

.faq-item.active .faq-answer {
    max-height: 1000px; /* Arbitrary large value to accommodate all text */
    opacity: 1;
    padding: 1rem;
}

.faq-divider {
    margin-top: 0.5rem; /* Reduced margin for less space after divider */
    border: 0;
    height: 1px;
    background: #ddd;
}
