.auto-apply-wrapper {
    text-align: left;
    margin-bottom: 20px;
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
    margin-bottom: 20px;
}

.auto-apply-title {
    font-size: 36px;
    font-weight: 500;
    margin-right: auto; /* Ensure the title is aligned to the left */
}

.toggle-button-wrapper {
    display: flex;
    align-items: center;
}

.edit-application-link {
    font-size: 16px;
    color: #ccc;
    margin-right: 10px; /* Space between link and button */
}

.auto-apply-container {
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #e5e7eb; /* Light gray border to match the screenshot */
    border-radius: 20px; /* Adjusted border-radius for softer corners */
    width: 750px;
    height: 580px; /* Adjust height as needed */
    margin: 0 auto;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow for the outline */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items to the top */
}

.toggle-button {
    width: 100px; /* Width of the toggle button */
    height: 40px; /* Height of the toggle button */
    background-color: #e5e7eb;
    border: 1px solid #ccc;
    border-radius: 90px; /* Border radius to make it oval */
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggle-button:before {
    content: '';
    position: absolute;
    width: 32px; /* Adjusted width */
    height: 32px; /* Adjusted height */
    border-radius: 50%;
    background-color: white;
    transition: transform 0.3s ease;
    top: 3px; /* Aligns the circle vertically within the button */
}

.toggle-button.off:before {
    transform: translateX(-30px); /* Adjusted for new width */
}

.toggle-button.on:before {
    transform: translateX(28px); /* Align with left edge */
}

.toggle-button.off {
    background-color: #f44336; /* Green background for ON */
}

.toggle-button.on {
    background-color: #4caf50; /* Red background for OFF */
}

.toggle-button.disabled {
    background-color: #cccccc;
    cursor: not-allowed;
    opacity: 0.6;
    position: relative;
    border: 1px solid #000;
}

.lock-icon {
    position: absolute;
    color: #333;
    font-size: 24px;
}

.button-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 90px; /* Match the border-radius of the button */
    cursor: not-allowed;
    z-index: 1;
}

.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
    z-index: 269;
}

.popup {
    position: relative;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    text-align: center;
}

.popup button.close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}
